.logo{
  width: 300px;
  padding: 10px;
}



$theme-colors: (
  "primary": #0395d1,
  "danger": #ff4136,
);

$primary: #0395d1;
$danger: #ff4136;

@import "~bootstrap/scss/bootstrap";

.btn{
  color: white;
}

:root {
$font-family-sans-serif: "AvenirNext", Arial, Helvetica, sans-serif;
$font-size-base: 0.94rem;

$spacer: 1rem;
}

h1{
  color: $primary;
}
.badge{
  margin: 5px;
}

.rdt_TableHeadRow{
  font-weight: bold;
}

.current{
  td{
    color: $danger;
    
  }
}

.spk_1{
  background-color: #eee;
}

.error{
  // background-image: url('../public/warning.jpg');
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: 0% 50%;
  // padding-left: 150px;
  margin: 25px;
  color: $primary;
  font-style: italic;
  min-height: 120px;
}

.backToTP{
  padding: 10px;
  margin: auto;
}

footer{
  margin-top: 50px;
  padding: 10px;
  background-color: #eee;
  text-align: center;
  font-size: smaller;
}

.speed-control button{
  font-size: smaller;
  padding-left: 20px;
  padding-right: 20px;
  
}

.transcript-table {
  max-height: 60vh;
  overflow-y: scroll;
  margin-bottom: 20px;
}